import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Box, Card, Grid, Image, Link, Text } from 'theme-ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`About me`}</h2>
    <blockquote>
      <p parentName="blockquote">{`I have a deep-rooted passion for learning, leadership, and making an impact.`}</p>
    </blockquote>
    <p>{`As a 5th year computer science and business administration double-degree student at the University of Waterloo and Wilfrid Laurier University,
I believe I bring the perfect balance between technical and interpersonal skills.`}</p>
    <p>{`I have previously interned at:`}</p>
    <Grid gap={2} columns={3} mdxType="Grid">
      <Box p={1} mdxType="Box">
        <Card sx={{
          maxWidth: 128
        }} mdxType="Card">
          <Link href='https://databricks.com/' mdxType="Link">
            <Image alt='databricks' src='databricks.png' mdxType="Image" />
          </Link>
          <Text sx={{
            fontSize: 1,
            textAlign: 'center'
          }} mdxType="Text">
    Databricks
          </Text>
        </Card>
      </Box>
      <Box p={1} mdxType="Box">
        <Card sx={{
          maxWidth: 128
        }} mdxType="Card">
          <Link href='https://tesla.com/' mdxType="Link">
            <Image alt='tesla' src='tesla.png' mdxType="Image" />
          </Link>
          <Text sx={{
            fontSize: 1,
            textAlign: 'center'
          }} mdxType="Text">
    Tesla
          </Text>
        </Card>
      </Box>
      <Box p={1} mdxType="Box">
        <Card sx={{
          maxWidth: 128
        }} mdxType="Card">
          <Link href='https://coinbase.com/' mdxType="Link">
            <Image alt='coinbase' src='coinbase.png' mdxType="Image" />
          </Link>
          <Text sx={{
            fontSize: 1,
            textAlign: 'center'
          }} mdxType="Text">
    Coinbase
          </Text>
        </Card>
      </Box>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      