import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Mt. Olympus" link="https://github.com/themarkrizkallah/mt-olympus" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  A cryptocurrency exchange and matching engine built using Go, Kafka, Postgres, Redis, Protobuf, and Docker.
    </ProjectCard>
    <ProjectCard title="Ray Tracer" link="https://github.com/themarkrizkallah/mt-olympus" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  A C++ Ray Tracer that supports reflections, supersampling, hierarchical transformations, and bounding-box acceleration.
    </ProjectCard>
    <ProjectCard title="Puppet" link="https://github.com/themarkrizkallah/mt-olympus" bg="linear-gradient(to right, #c79081 0%, #dfa579 100%)" mdxType="ProjectCard">
  A 3D interactive hierarchically modelled puppet with manipulable joints built using C++ and OpenGL.
    </ProjectCard>
    <ProjectCard title="Maze" link="https://github.com/themarkrizkallah/zenith" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  A 3D maze with a built-in breadth-first-search solver written in C++ and OpenGL. 
    </ProjectCard>
    <ProjectCard title="Zenith" link="https://github.com/themarkrizkallah/zenith" bg="linear-gradient(to right, #13547a 0%, #80d0c7 100%)" mdxType="ProjectCard">
    A Stellar Lumen(XLM) CLI wallet written in Go.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      